<template>
  <div class="container">
    <img class="top" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/YD_01.jpg" />

    <div class="form" style="padding: 40px 0 20px 0;">
      <img class="step1" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone_number" type="tel" maxlength="11" placeholder="请输入您的手机号码" />
      <img class="step2" v-if="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
      <img class="step3" v-if="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
      <van-field v-model="form.phone_code" type="number" center maxlength="6" autocomplete="one-time-code"
        placeholder="请输入短信验证码" class="input-code">
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span> {{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>

      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/YD_submit.png" class="submit"
        @click="onSubmit" />

    </div>
    <div class="info">
      <img style="width: 100%;" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/YD_02.jpg" />
    </div>
    <div class="rule">
      <div class="rule-title">活动规则</div>
      {{ ruleText }}
    </div>
    <!-- 二确 -->
    <BaseDialogConfirm :visibility.sync="visibility" :closeBtnImg="closeBtnImg" :submitBtnImg="submitBtnImg"
      :background="background" />
  </div>
</template>

<script>
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";
import { sendSMS, buy } from "@/api/qiuMoFusion/huilong_01.js";
import { generateSign, JuDuoDuo } from "@/utils/common";
export default {
  components: {
    BaseDialogConfirm
  },
  data() {
    return {
      yzm_loading: false,
      form: {
        phone_number: "",
        phone_code: "",
        // request_time: "",
        // request_id: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      num: 1,
      visibility: false,
      userAgent: "",
      closeBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/cancel_02.png",
      submitBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/YD_ok01.png",
      background: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/All/YD_bg01.png",
      ruleText: `
1、活动对象：移动手机用户。
2、活动内容：活动期间凡首次在本页面成功开通业务（业务名称：移动娱乐联合会员，业务资费：20元/月，具体资费以短信为准），即可获得热门会员月卡（15选1）。
3、权益说明：
15个热门会员月卡任选一
会员月卡包括：爱奇艺黄金会员（月卡）、腾讯视频会员（月卡）、优酷黄金会员（月卡）、搜狐视频VIP(月卡）、芒果视频VIP（月卡）、网易云音乐VIP(月卡)、QQ音乐豪华绿钻（月卡）、哔哩哔哩大会员（月卡）、蜻蜓FM会员（月卡）、喜马拉雅会员（月卡）、QQ阅读会员（月卡）、百度网盘VIP会员（月卡）、新浪微博会员（月卡）、酷狗音乐（月卡）、懒人听书VIP会员（月卡），每月可选其中一个。
使用方式：
会员领取方式：进入公众号“乐兑喵”，点击底部菜单栏“权益兑换”，输入手机号登录后选择会员权益领取，用户确保输入手机号为订购成功的手机号，并且手机号准确无误，系统将24小时内自动为用户充值权益；
4、温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。
如活动期内相关热门权益因库存不足或其他原因暂时无法兑换，用户可选择当月内延后兑换或者进行其他等价值的权益兑换。给您带来的不便，敬请谅解。
5、注意事项：
（1）每个手机号仅限参与一次，如当月不退订，次月自动续订；
（2）本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放（福利礼品包括会员权益、话费、奖品等）；
（3）已开通本业务的老用户不可再次参与本次推广活动。
（4）成功开通本业务，获取的权益福利不超过12个月，权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。
6、退订方式：
发送短信0000至10086，按提示内容退订。
7、如有疑问可致电客服热线：4001189599`
    };
  },
  mounted() {
    const that = this;

    window.addEventListener("scroll", that.handlerscroll);
    window.onpopstate = function (event) {
      // 禁止返回上一页
      // history.go(1);
      JuDuoDuo("100023", that.form.phone_number, "娱乐联合会员20元");
      // liuliangguo("103696", that.form.phone_number, "娱乐联合会员20元");
    };
  },
  created() {
    this.userAgent = navigator.userAgent;
    const { phone = "" } = this.$route.query;
    this.form = {
      ...this.form,
      callback: location.search,
      phone_number: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  beforeDestroy() {
    // 销毁时移除事件监听，防止影响其他页面
    window.onpopstate = null;
  },
  watch: {
    form: {
      handler(value) {
        const { phone_number = "", phone_code } = value;
        if (phone_number.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && phone_code.length == 0) {
          this.num = 2;
        } else if (phone_code.length < 6) {
          this.num = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

    dialogInfo() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息授权保护协议",
        message: `尊敬的客户：
  您好！
  根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
   为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
  （1）事先获得客户的明确授权；
  （2）根据有关的法律法规要求；
  （3）按照相关司法机关和/或政府主管部门的要求；
  （4）为维护社会公众的利益所必需且适当；
  （5）为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
    handleRule() {
      this.$router.push({
        path: "/monthlyHotTopicsNTo2_YunXian/rule",
      });
    },
    async getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      const {
        phone_number = "",
        // request_time = "",
        // request_id = "",
      } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.yzm_loading = true;
      try {
        const res = await sendSMS(
          {
            product_id: "72801001",
            phone_number,
            url: document.URL,
            default_app_name: "无",
            default_package_name: "无",
            app_name: "无",
            auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
          }
        );

        if (res.errorCode === "000") {
          this.form = {
            ...this.form,
            response_order_id: res.response_order_id,
          };
          // 设置倒计时秒
          this.sendAuthCode = false;
          this.auth_time = 120;
          let auth_timetimer = setInterval(() => {
            this.auth_time--;
            if (this.auth_time <= 0) {
              this.sendAuthCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        } else {
          this.$dialog.alert({ message: res.errorMsg });
          JuDuoDuo("100023", this.form.phone_number, "娱乐联合会员20元");
          // liuliangguo("103697", this.form.phone_number, "娱乐联合会员20元");
        }
      } finally {
        this.yzm_loading = false;
      }
    },
    onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone_number) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.phone_code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      }
      // else if (!this.checked) {
      //   this.$dialog.alert({ message: "请勾选并同意相关协议" });}
      else {
        this.visibility = true;
      }
    },
    async handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await buy(
        { ...this.form, auth: generateSign("QIUMO", "YUVFIUC(*OVO()") },
      );
      this.visibility = false;
      this.$toast({
        duration: 8000,
        message: res.errorMsg,
      });
      if (res.errorCode === "000") {
        JuDuoDuo("100023", this.form.phone_number, "娱乐联合会员20元");
        // liuliangguo("103694", this.form.phone_number, "娱乐联合会员20元");
      } else {
        JuDuoDuo("100023", this.form.phone_number, "娱乐联合会员20元");
        // liuliangguo("103695", this.form.phone_number, "娱乐联合会员20元");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;

  background-color: #e14a43;

  .top {
    width: 100%;
  }

  .form {
    position: relative;

    .step1,
    .step2,
    .step3 {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .step1 {
      transform: translate(80px, -80px);
    }

    .step2 {
      width: 140px;
      right: 60px;
      top: 150px;
    }

    .step3 {
      width: 450px;
      transform: translate(84px, -20px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 106px;
      background-color: #ffffff;
      margin: 0 auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 50px;
      border-radius: 53px;
      // border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 252px;
          height: 106px;
          text-align: center;
          line-height: 106px;
          color: #fff;
          font-size: 34px;
          font-weight: normal;
          background-color: #ff840d;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #4d2f1c;
        font-weight: normal;
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 40;
          color: #969696;
        }
      }
    }

    .submit {
      width: 654px;
      display: block;
      margin: 87px auto 0;
      animation: identifier 1s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(0.9);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    .checked {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      ::v-deep .van-checkbox__label {
        color: #ffffff;
      }

      .book {
        color: #ffffff;
      }
    }
  }

  .info {

    // margin-top: 50px;
    .title {
      width: 251px;
      display: block;
      padding: 81px 0 24px 0;
      margin: 0 auto;
    }

    .box {
      padding: 0 28px 100px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .rule {
    white-space: pre-wrap;
    font-size: 26px;
    padding: 0 20px 20px;
    line-height: 40px;

    .rule-title {
      font-size: 34px;
      font-weight: 900;
      text-align: center;
      line-height: 40px;
    }
  }
}
</style>